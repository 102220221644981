import landscapeSrc from "./landscape.svg";
import portraitSrc from "./portrait.svg";

const peakPlaceHolder = {
  landscape: {
    src: landscapeSrc,
    srcWidth: 1920,
    srcHeight: 1280,
    alt: "",
  },
  portrait: {
    src: portraitSrc,
    srcWidth: 1280,
    srcHeight: 1920,
    alt: "",
  },
};

export default peakPlaceHolder;
