import "@sphtech/dmg-design-system/dist/theme-peak.css";
import "@sphtech/dmg-design-system/assets/peak/fonts.css";

import { configSite } from "@pub/config";
import { BaseLayout, CustomClassContext } from "@sphtech/dmg-design-system";
import Newsticker from "@src/app/components/Modules/Newsticker/Newsticker";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { configHeader, hamburgermenuSvgSource } from "./config/configHeader";
import customStyle from "./custom.module.css";
import logoSvgSource from "./logo.svg?raw";
import { RoutePaths } from "./routePaths";

type PEAKBaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  skipMobileBanner?: boolean;
};

/**
 * Reusable Base layout specific to PEAK.
 */
const PEAKBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  skipMobileBanner,
}: PEAKBaseLayoutProps) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();

  return (
    <CustomClassContext.Provider
      value={{
        button: {
          primary: customStyle["peak-button-primary"],
          secondary: customStyle["peak-button-secondary"],
        },
        textLink: customStyle["peak-text-link"],
        anchorBlock: {
          verticalLine: customStyle["peak-anchor-block"],
          summaryItem: customStyle["peak-anchor-block-summary-item"],
        },
        relatedItem: {
          verticalLine: customStyle["verticalLine"],
          firstItem: customStyle["verticalLineFirstItem"],
        },
        videoGallery: {
          verticalLine: customStyle["peak-video-gallery"],
        },
        magazineListing: {
          verticalLine: customStyle["peak-magazine-listing"],
        },
        navLinks: {
          verticalLineHeader: customStyle["peak-nav-links"],
          verticalLineFooter: customStyle["peak-nav-links"],
        },
        callBlock: {
          verticalLine: customStyle["peak-call-block"],
        },
        authorProfile: {
          verticalLine: customStyle["verticalLine"],
        },
      }}
    >
      <BaseLayout
        siteName={configSite.name}
        ArticleTitle={ArticleTitle}
        ads={ads}
        leaderboard={leaderboard}
        NewsTicker={<Newsticker />}
        RoutePaths={RoutePaths}
        svgSource={logoSvgSource}
        svgHamburgerMenu={hamburgermenuSvgSource}
        mciText={
          <span suppressHydrationWarning>
            MDDI (P) 062/10/2024. Published by SPH Media Limited, Co. Regn. No.
            202120748H. Copyright © {currentYear} SPH Media Limited. All rights
            reserved.
          </span>
        }
        socialIconProps={configHeader.socialMedia}
        megaMenuProps={{
          NewsLetterProps: configHeader.megaMenu.newsletter,
          CloseButtonProps: configHeader.megaMenu.closeButton,
          divider: configHeader.megaMenu.divider?.footer,
        }}
        skipMobileBanner={skipMobileBanner}
        currentLocation={location}
      >
        <Helmet
          htmlAttributes={{
            lang: "en-SG",
          }}
        >
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b92427" />
          <meta name="msapplication-TileColor" content="#b92427" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        {children}
      </BaseLayout>
    </CustomClassContext.Provider>
  );
};

export default PEAKBaseLayout;
